import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../appContext";
import Dialog from "../../components/Dialog";
import LabController from "../../controllers/LabController";
import IdNameDisplay from "../../controllers/IdNameDisplay";
import {isNaturalNumber, stringRequired, useValidation} from "../../validation";
import TestCarohydrateMatch from "../../controllers/TestCarohydrateMatch";
import Input from "../../components/Input";
import {arrayPush, arrayUpdatePartial} from "../../immutableState";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import {showSuccessOrFailed} from "../../Snacks";
import {classNames} from "../../wrapper";
import {inputStyling} from "../../styles";
import SampleNumber from "../../components/SampleNumber";
import SelectNumber from "../../components/SelectNumber";
import IdName from "../../controllers/IdName";
import SelectNumberNullable from "../../components/SelectNumberNullable";
import CarbohydrateTissueWithOrgans from "../../controllers/CarbohydrateTissueWithOrgans";

export function nextNumber(value: number | string): string {
    if (typeof value === "number")
        return (value + 1).toString();

    if (typeof value === "string") {
        // extract the code and number part. ei AT-VTCP19281
        const res = /\d+/.exec(value);
        if (res == null)
            return "";

        const number = parseInt(value.substring(res.index)) + 1;
        return value.substring(0, res.index) + number;
    }
    return "";
}

export const emptyEntry: CropLabResultPrepareRequest = {
    sampleNumber: "",
    resolved: false,
    entity: "",
    agent: "",
    agronomist: "",
    farmName: "",
    fieldBlockNumber: "",
    sampleDate: new Date(),
    tissueId: 0,
    testCarboCropGrowthId: 0,
    testCarbohydrateId: null,
    cropId: 0,
    cultivarId: null,
    cultivars: [],
    regionId: null,
    regions: [],
    labResultsId: 0,
    matches: [],
}

export interface CropLabResultPrepareRequest {
    sampleNumber: string,
    entity: string,
    agent: string,
    agronomist: string,
    farmName: string,
    fieldBlockNumber: string,
    sampleDate: Date,
    tissueId: number,
    testCarboCropGrowthId: number,
    testCarbohydrateId: number | null,
    cropId: number,
    cultivarId: number | null,
    cultivars: IdName[],
    regionId: number | null,
    regions: IdName[],
    labResultsId: number,
    resolved: boolean,
    
    matches: TestCarohydrateMatch[];
}

export type JobStatus = 'closed' | 'create' | number;

interface Job {
    id: number;
    receivedOn: Date;
    entries: CropLabResultPrepareRequest[];
}

const CreateJob: React.FC<{
    // there is three states: 
    // 1. JobId = null then the dialog is closed
    // 2. JobId = 0 then the create job dialog is opened
    // 3. JobId is number >0 then get the job data and show the edit job dialog
	jobId: JobStatus
    setModelValue: (moduleValue: boolean) => void
}> = (props) => {
    const context = useContext(AppContext);
    const tableRef = useRef<HTMLTableElement>(null);
    const [job, setJob] = useState<Job>({
        id: 0,
        receivedOn: new Date(),
        entries: [],
    })
    const [showJob, setShowJob] = useState(false)
    const [crops, setCrops] = useState<IdNameDisplay[]>([])
    const [tissueTypes, setTissueTypes] = useState<CarbohydrateTissueWithOrgans[]>([])

    // global column to set for each rows
    const [columns, setColumns] = useState<CropLabResultPrepareRequest>(emptyEntry)

    const validation = useValidation({
        entries: () => validEntries(),
        minOneEntry: () => job.entries.length > 0
    });

    useEffect(() => {
        const hideLoader = context.showLoader()
        if (props.jobId == 'create') setShowJob(true)
        Promise.all([
            LabController.cropsActive().then(resp => {
                setCrops(resp.data)
                return resp.data
            }),
            LabController.tissueTypesActive().then(resp => {
                setTissueTypes(resp.data)
                return resp.data
            })
        ]).then(([cropValues, tissueTypes]) => {
            if (typeof props.jobId != 'number') return 
            LabController.getData({ id: props.jobId }).then(resp => {
                setJob({id: resp.data.id, receivedOn:resp.data.receivedOn, entries: resp.data.entries.map<CropLabResultPrepareRequest>(l => ({
                        newEntry: false,
                        sampleNumber: l.sampleNumber,
                        entity: l.entity,
                        agent: l.agent,
                        agronomist: l.agronomist,
                        farmName: l.farmName,
                        fieldBlockNumber: l.fieldBlockNumber,
                        sampleDate: l.sampleDate,
                        tissueId: l.tissueId,
                        testCarboCropGrowthId: l.testCarboCropGrowthId,
                        testCarbohydrateId: l.testCarbohydrateId,
                        labResultsId: l.labResultsId,
                        cropId: l.cropId,
                        cultivarId: l.cultivarId,
                        cultivars: getCultivarsForCropId(l.cropId, cropValues),
                        regionId: l.regionId,
                        regions: getRegionsForCropId(l.cropId, cropValues),
                        resolved: l.testCarbohydrateId != null,
                        matches: []
                    }))})
                setShowJob(true)
            })
        }).finally(hideLoader)

    }, [props.jobId])

    useEffect(() => {
        nextRow(0);
    }, [])

    function validEntries(): boolean {
        return job.entries.every(e =>
            stringRequired(e.entity, e.farmName, e.fieldBlockNumber, e.agent, e.agronomist) &&
            isNaturalNumber(e.testCarboCropGrowthId, e.tissueId, e.cropId, e.cultivarId)
        )
    }

    function removeEntry(index: number) {
        const entriesSpliced = job.entries.filter((ent, i) => i !== index)
        setJob({...job, entries: entriesSpliced})
    }

    
    function setCultivarsAndRegionsForCropId(cropId: number, index: number) {
        setJobEntry(index, 
            {
                cultivars: getCultivarsForCropId(cropId), 
                cropId: cropId, 
                regions: getRegionsForCropId(cropId),
                testCarboCropGrowthId: 0,
            })
    }
    
    function getCultivarsForCropId(cropId: number, cropValues: IdNameDisplay[] = crops) {
        const crop = cropValues.find(c => c.id === cropId)
        if (crop) {
            return crop.cultivars
        }
        return []
    }

    function getRegionsForCropId(cropId: number, cropValues: IdNameDisplay[] = crops) {
        const crop = cropValues.find(c => c.id === cropId)
        if (crop) {
            return crop.regions
        }
        return [];
    }

    function mergeCropLabResult(existing: CropLabResultPrepareRequest, option: TestCarohydrateMatch, matches: TestCarohydrateMatch[]): CropLabResultPrepareRequest {
        return {
            resolved: true,
            sampleDate: existing.sampleDate ?? option.date,
            sampleNumber: option.sampleNumber,
            entity: existing.entity || option.entity,
            agent: existing.agent || option.agent,
            agronomist: existing.agronomist || option.agronomist,
            farmName: existing.farmName || option.farmName,
            fieldBlockNumber: existing.fieldBlockNumber || option.fieldBlockNumber,
            testCarbohydrateId: existing.testCarbohydrateId ?? option.testCarbohydrateId,
            tissueId: existing.tissueId || option.tissueId,
            labResultsId: existing.labResultsId,
            testCarboCropGrowthId: existing.testCarboCropGrowthId,
            cropId: filterValidCropId(existing.cropId || option.cropId),
            cultivarId: existing.cultivarId ?? option.cultivarId,
            cultivars: getCultivarsForCropId(existing.cropId || option.cropId),
            regionId: existing.regionId ?? option.regionId,
            regions: getRegionsForCropId(existing.cropId || option.cropId),
            matches
        }
    }
    
    function setCultivarsForCropIdCols(cropId: number) {
        const crop = crops.find(c => c.id === cropId)
        if (crop) {
            setColumnAndRows({cultivars: crop.cultivars, cropId: cropId, regions: crop.regions})
            return 
        }
        setColumnAndRows({cultivars: [], cropId: cropId})
    }

    function setJobEntry(index: number, update: Partial<CropLabResultPrepareRequest>) {
        setJob(j => ({...j, entries: arrayUpdatePartial<CropLabResultPrepareRequest>(j.entries, index, update)}))
    }

    function upsert() {
        if (!validation.validate()) {
            return;
        }

        showSuccessOrFailed(context, LabController.upsertJob(job)).then(() => {
            props.setModelValue(false)
            cancel()
        })
    }

    function required(val: string | number | null) {
        if (validation.rules.entries)
            return false;

        if (val === null) return true
        if (typeof val === 'string') {
            return val.trim() === ''
        }
        return val === 0
    }

    function cancel() {
        setShowJob(false)
        setColumns(emptyEntry)
        props.setModelValue(false)
        setJob({...job, entries: [emptyEntry]})
    }

    function focusTo(column: number, index: number) {
        (tableRef.current
            ?.querySelector('tbody')
            ?.querySelectorAll('tr')[index]
            ?.querySelectorAll('input, select')[column] as HTMLInputElement)?.focus()
    }

    function filterGrowths(cropId: number, tissueId: number) {
        if (!cropId || !tissueId) return []
        
        let found = tissueTypes.find(t => t.id === tissueId)
        if (!found) return []
        
        return found.organs.filter(o => o.cropId == cropId)
    }

    function getNextNumber(prevNumber: string | undefined) {
        if (prevNumber) {
            return nextNumber(prevNumber)
        }
        return ''
    }

    function newEntry() {
        newEntryAdd()
    }

    function newEntryAdd(sampleNumber?: string) {
        const prevNumber = job.entries.length > 0
            ? job.entries[job.entries.length - 1]?.sampleNumber
            : "";
        
        setJob({
            ...job,
            entries: arrayPush(job.entries, {...emptyEntry, sampleNumber: sampleNumber ?? getNextNumber(prevNumber)})
        })
        
    }

    function nextRow(index: number, sampleNumber?: string) {
        if (job.entries.length - 1 <= index) {
            newEntryAdd(sampleNumber)
        } else {
            focusTo(0, index + 1)
        }
    }

    function filterValidCropId(id: number): number {
        return crops.some(c => c.id === id) ? id : 0
    }
    
    function onSampleNumberChange(j: Job, v: string, option: TestCarohydrateMatch | null, index: number, setIfFound: boolean) {
        if (option != null) {
            const existing = j.entries[index]!;
            setJobEntry(index, mergeCropLabResult(existing, option, []));
            return
        }

        setJobEntry(index, {
            sampleNumber: v,
            testCarbohydrateId: null
        });
        
        // search for matches
        LabController.matches({value: v, jobId: j.id == 0 ? null : j.id}).then(resp => {
            if (setIfFound && resp.data.length == 1) {
                const existing = j.entries[index]!;
                if (!existing) {
                    return;
                }
                setJobEntry(index, mergeCropLabResult(existing, resp.data[0]!, resp.data));
                return;
            }
            setJobEntry(index, {
                matches: resp.data,
            });
        })
    
    }

    function inputRequired(input: string | number | null) {
        return inputStyling(required(input))
    }

    function handlePaste(event: React.ClipboardEvent<HTMLInputElement>, resolved: boolean, rowIndex: number, partial: (value: string) => Partial<CropLabResultPrepareRequest>) {
        const text = event.clipboardData.getData('text')
        const lines = (text.includes('\n') ? text.split('\n') : text.split('\t'))
            .map(t => t.replace('\r', ''))
        
        if (!text || text === '' || lines.length === 0) {
            return
        }

        const entries = new Array(Math.max(lines.length + rowIndex, job.entries.length))
            .fill(null)
            .map<CropLabResultPrepareRequest>((_, index) => {
                const merge = job.entries[index] ?? emptyEntry;
                
                const lineIndex = index - rowIndex;
                if (lineIndex >= 0 && lineIndex < lines.length) {
                    return {
                        ...merge,
                        ...partial(lines[lineIndex]!),
                        resolved,
                    }
                }
                
                return merge
            });
        
        const newJob = {...job, entries}; 
        setJob(newJob);
        // setJob does not take immediate effect
        // pass newJob in onSampleNumberChange
        
        // perform a match search on the entries created.
        // change trigger
        for (let i = rowIndex; i < Math.min(entries.length, rowIndex + lines.length); i++) 
        {
            const entry = entries[i]!;
            onSampleNumberChange(newJob, entry.sampleNumber, null, i, true);
        }
        
        event.preventDefault()
    }

    function setColumnAndRows(partial: Partial<CropLabResultPrepareRequest>) {
        setColumns({...columns, ...partial})
        setJob({...job, entries: job.entries = job.entries.map(e => ({...e, ...partial}))})
    }

    return (
        <div>
            <Dialog title={"Job Card Details"}
                    show={showJob}
                    setShow={() => cancel()}
                    body={
                        <div className="p-2">
                            <table>
                                <thead>
                                <tr>
                                    <th className='text-left pr-3'>Date Received</th>
                                    <td className='px-3'>
                                        <DatePicker value={new Date(job.receivedOn)}
                                                    setValue={val => setJob({...job, receivedOn: new Date(val)})}
                                                    type={DatePickerType.Date}/>
                                    </td>
                                </tr>
                                </thead>
                            </table>


                            <div className='flex items-center'>
                                <div className="btn bg-primary" onClick={newEntry}>Add Entry</div>
                                {
                                    !validation.rules.minOneEntry ? <div className='m-1 text-red-500'>
                                        At least one entry is required.
                                    </div> : null
                                }
                            </div>

                            <table ref={tableRef}>
                                <thead>

                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className='text-left'>
                                        <DatePicker value={new Date(columns.sampleDate)}
                                                    setValue={val => setColumnAndRows({sampleDate: new Date(val)})}
                                                    type={DatePickerType.Date}/>
                                    </th>
                                    <th className='text-left'>
                                        <Input value={columns.entity} change={v => setColumnAndRows({entity: v})}/>
                                    </th>
                                    <th className='text-left'>
                                        <Input value={columns.agent} change={v => setColumnAndRows({agent: v})}/>
                                    </th>
                                    <th className='text-left'>
                                        <Input value={columns.agronomist}
                                               change={v => setColumnAndRows({agronomist: v})}/>
                                    </th>
                                    <th className='text-left'>
                                        <Input value={columns.farmName} change={v => setColumnAndRows({farmName: v})}/>
                                    </th>
                                    <th className='text-left'>
                                        <Input value={columns.fieldBlockNumber}
                                               change={v => setColumnAndRows({fieldBlockNumber: v})}/>
                                    </th>
                                    <th className='text-left'>
                                        <SelectNumber
                                            options={crops}
                                            textFunc={c => c.name}
                                            valueFunc={c => c.id}
                                            value={columns.cropId}
                                            onChange={v => {
                                                setCultivarsForCropIdCols(v)
                                            }}
                                        />
                                    </th>
                                    <th className='text-left'>
                                        <SelectNumberNullable
                                            options={columns.cultivars}
                                            textFunc={c => c.name}
                                            valueFunc={c => c.id}
                                            value={columns.cultivarId}
                                            onChange={v => setColumnAndRows({cultivarId: v})}
                                        />
                                    </th>
                                    <th className='text-left'>
                                        <SelectNumberNullable
                                            options={columns.regions}
                                            textFunc={c => c.name}
                                            valueFunc={c => c.id}
                                            value={columns.regionId}
                                            onChange={v => setColumnAndRows({regionId: v})}
                                        />
                                    </th>
                                    <th className='text-left'>
                                        <SelectNumber
                                            options={tissueTypes}
                                            textFunc={c => c.name ?? ''}
                                            valueFunc={c => c.id}
                                            value={columns.tissueId}
                                            onChange={v => setColumnAndRows({tissueId: v})}
                                        />
                                    </th>
                                    <th className='text-left'>
                                        <SelectNumber
                                            options={filterGrowths(columns.cropId, columns.tissueId)}
                                            textFunc={c => c.name}
                                            valueFunc={c => c.id}
                                            value={columns.testCarboCropGrowthId}
                                            onChange={v => setColumnAndRows({testCarboCropGrowthId: v})}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th className='whitespace-nowrap text-left pr-1'></th>
                                    <th className='whitespace-nowrap text-left'>Sample Number</th>
                                    <th className='text-left pl-1'>Date of Sampling</th>
                                    <th className='text-left pl-1'>Entity</th>
                                    <th className='text-left pl-1'>Agent</th>
                                    <th className='text-left pl-1'>Agronomist</th>
                                    <th className='text-left pl-1'>Farm Name</th>
                                    <th className='text-left pl-1'>Field Block Number</th>
                                    <th className='text-left pl-1'>Crop</th>
                                    <th className='text-left pl-1'>Cultivar</th>
                                    <th className='text-left pl-1'>Region</th>
                                    <th className='text-left pl-1'>Organ Type</th>
                                    <th className='text-left pl-1'>Growth</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    job.entries.map((entry, index) => <tr key={index}>
                                            <td className='pr-1'>
                                                {
                                                    entry.testCarbohydrateId != null
                                                        ? <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                               viewBox="0 0 24 24"
                                                               stroke="currentColor"
                                                               className="w-6 h-6 text-primary-600 stroke-2">
                                                            <path
                                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             stroke="currentColor"
                                                             className="w-6 h-6 text-red-500 stroke-2">
                                                            <path
                                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>
                                                }
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(2, index) : null}>

                                                <SampleNumber
                                                    autoFocus={true}
                                                    className={classNames(inputRequired(entry.sampleNumber), '')}
                                                    value={entry.sampleNumber}
                                                    onSampleResolve={(value, sample) => {
                                                        onSampleNumberChange(job, value, sample, index, false);
                                                    }}
                                                    onPaste={v => handlePaste(v, false, index, v => ({sampleNumber: v}))}
                                                    matches={entry.matches}
                                                />
                                            </td>
                                            <td className='pr-1'>
                                                <DatePicker value={new Date(entry.sampleDate)}
                                                            setValue={val => setJobEntry(index, {sampleDate: new Date(val)})}
                                                            type={DatePickerType.Date}/>
                                            </td>

                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(3, index) : null}>
                                                <Input className={inputRequired(entry.entity)} value={entry.entity ?? ''}
                                                       change={val => setJobEntry(index, {entity: val})}
                                                       onPaste={v => handlePaste(v, true, index, v => ({entity: v}))}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(4, index) : null}>
                                                <Input className={inputRequired(entry.agent)} value={entry.agent ?? ''}
                                                       change={val => setJobEntry(index, {agent: val})}
                                                       onPaste={v => handlePaste(v, true, index, v => ({agent: v}))}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(5, index) : null}>
                                                <Input className={inputRequired(entry.agronomist)}
                                                       value={entry.agronomist ?? ''}
                                                       change={val => setJobEntry(index, {agronomist: val})}
                                                       onPaste={v => handlePaste(v, true, index, v => ({agronomist: v}))}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(6, index) : null}>
                                                <Input className={inputRequired(entry.farmName)} value={entry.farmName}
                                                       change={val => setJobEntry(index, {farmName: val})}
                                                       onPaste={v => handlePaste(v, true, index, v => ({farmName: v}))}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(7, index) : null}>
                                                <Input className={inputRequired(entry.fieldBlockNumber)}
                                                       value={entry.fieldBlockNumber}
                                                       onPaste={v => handlePaste(v, true, index, v => ({fieldBlockNumber: v}))}
                                                       change={val => setJobEntry(index, {fieldBlockNumber: val})}/>
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(8, index) : null}>
                                                <SelectNumber
                                                    className={inputRequired(entry.cropId)}
                                                    options={crops}
                                                    textFunc={c => c.name}
                                                    valueFunc={c => c.id}
                                                    value={entry.cropId}
                                                    onChange={v => {
                                                        setCultivarsAndRegionsForCropId(v, index)
                                                    }}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(9, index) : null}>
                                                <SelectNumberNullable
                                                    className={inputRequired(entry.cultivarId)}
                                                    options={entry.cultivars}
                                                    textFunc={c => c.name}
                                                    valueFunc={c => c.id}
                                                    value={entry.cultivarId} // cultivar id should not be nullable 
                                                    onChange={v => {
                                                        setJobEntry(index, {cultivarId: v})
                                                    }}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(10, index) : null}>
                                                <SelectNumberNullable
                                                    options={entry.regions}
                                                    textFunc={c => c.name}
                                                    valueFunc={c => c.id}
                                                    value={entry.regionId}
                                                    onChange={v => {
                                                        setJobEntry(index, {regionId: v})
                                                    }}
                                                />
                                            </td>
                                            <td className='pr-1' onKeyDown={e => e.key === "Enter" ? nextRow(index) : null}>
                                                <SelectNumber
                                                    className={inputRequired(entry.tissueId)}
                                                    options={tissueTypes}
                                                    textFunc={c => c.name ?? ''}
                                                    valueFunc={c => c.id}
                                                    value={entry.tissueId}
                                                    onChange={v => setJobEntry(index, {tissueId: v})}
                                                />
                                            </td>
                                            <td className='pr-1'
                                                onKeyDown={e => e.key === "Enter" ? focusTo(11, index) : null}>

                                                <SelectNumber
                                                    className={inputRequired(entry.testCarboCropGrowthId)}
                                                    options={filterGrowths(entry.cropId, entry.tissueId)}
                                                    textFunc={c => c.name}
                                                    valueFunc={c => c.id}
                                                    value={entry.testCarboCropGrowthId}
                                                    onChange={v => setJobEntry(index, {testCarboCropGrowthId: v})}
                                                />
                                            </td>
                                            <td className='pr-1'>
                                                <a className="underline cursor-pointer"
                                                   onClick={() => removeEntry(index)}>remove</a>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                            <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                                <button className="btn btn-error" onClick={cancel}>Cancel</button>
                                <div className="btn btn-primary" onClick={() => upsert()}>Submit</div>
                            </div>
                        </div>
                    }/>

        </div>
    )
}

export default CreateJob;