import {CultivarRef} from "./Cultivars";
import EntityGraphs from "../../controllers/EntityGraphs";
import {arrayUpdatePartial, useStateSetter, useStateSetterArray} from "../../immutableState";
import React, {useEffect, useImperativeHandle, useState} from "react";
import CheckBox from "../../components/CheckBox";
import ElementValue from "../../controllers/ElementValue";
import Number from "../../components/Number";
import {KeyOfType} from "../../components/Fields";
import MicroGraph from "../../controllers/MicroGraph";
import MacroGraph from "../../controllers/MacroGraph";
import SelectedDrisGraph from "../../controllers/SelectedDrisGraph";
import CropController from "../../controllers/CropController";
import GraphCloneDisplay from "../../controllers/GraphCloneDisplay";
import LookupName from "../../components/Lookup";
import Molybdenum from "../../controllers/Molybdenum";
import Nickel from "../../controllers/Nickel";

function MicroMacroGraph<T>(props: {
    items: ({ get: () => ElementValue, set: (value: ElementValue) => void, title: string })[]
}) {

    return <table>
        <thead>
        <tr>
            <td>Element</td>
            <td>Min</td>
            <td>Max</td>
        </tr>
        </thead>
        <tbody>
        {props.items.map((item, index) => {
            const value = item.get();

            return <tr key={index}>
                <td>{item.title} </td>
                <td>
                    <Number value={value.min} change={v => item.set({min: v, max: value.max, med: value.med})}/>
                </td>
                <td>
                    <Number value={value.max} change={v => item.set({max: v, min: value.min, med: value.med})}/>
                </td>
            </tr>
        })}
        </tbody>
    </table>
}

const microKeys: KeyOfType<MicroGraph, ElementValue>[] = ['fe', 'mn', 'na', 'zn', 'cu', 'b'];
const macroKeys: KeyOfType<MacroGraph, ElementValue>[] = ['n', 'p', 'k', 'ca', 'mg', 's'];
const molybdenumKeys: KeyOfType<Molybdenum, ElementValue>[] = ['mo'];
const nickelKeys: KeyOfType<Nickel, ElementValue>[] = ['ni'];


export interface GraphProp<T> {
    checked: boolean;
    setChecked: (value: boolean) => void;
    graph: T,
    setGraph: (partial: Partial<T>) => void
    
}

export function RenderMolybdenumAndNickel(
    molybdenum: GraphProp<Molybdenum>,
    nickel: GraphProp<Nickel>)
    
    {
        return <div className='w-full flex'>
                    <div className='border shadow w-1/2'>
                        <CheckBox renderLabel={true} checked={molybdenum.checked} onChange={v => molybdenum.setChecked(v)}>
                            <div className="text-2xl text-gray-500 align-middle inline-block">
                            MolybdenumGraph
                            </div>
                        </CheckBox>
                        {molybdenum.checked ?
                            <MicroMacroGraph items={molybdenumKeys.map(k => ({
                                title: k.charAt(0).toUpperCase() + k.substring(1),
                                get: () => molybdenum.graph[k],
                                set: (value: ElementValue) => molybdenum.setGraph({[k]: value})
                            }))}/> : null}
                    </div>
                    <div className='border shadow w-1/2'>
                        <CheckBox renderLabel={true} checked={nickel.checked} onChange={v => nickel.setChecked(v)}>
                            <div className="text-2xl text-gray-500 align-middle inline-block">
                            NickelGraph
                            </div>
                        </CheckBox>
                        {nickel.checked ?
                            <MicroMacroGraph items={nickelKeys.map(k => ({
                                title: k.charAt(0).toUpperCase() + k.substring(1),
                                get: () => nickel.graph[k],
                                set: (value: ElementValue) => nickel.setGraph({[k]: value})
                            }))}/> : null}
                    </div>
                </div>;
    }

export function RenderMicroAndMacro(
    micro: GraphProp<MicroGraph>,
    macro: GraphProp<MacroGraph>
) {
    return <div className='w-full flex'>
        <div className='border shadow w-1/2'>
            <CheckBox renderLabel={true} checked={micro.checked} onChange={v => micro.setChecked(v)}>
                <div className="text-2xl text-gray-500 align-middle inline-block">
                    MicroGraph
                </div>
            </CheckBox>
            {micro.checked ?
                <MicroMacroGraph items={microKeys.map(k => ({
                    title: k.charAt(0).toUpperCase() + k.substring(1),
                    get: () => micro.graph[k],
                    set: (value: ElementValue) => micro.setGraph({[k]: value})
                }))}/> : null}
        </div>
        <div className='border shadow w-1/2'>
            <CheckBox renderLabel={true} checked={macro.checked} onChange={v => macro.setChecked(v)}>
                <div className="text-2xl text-gray-500 align-middle inline-block">
                    MacroGraph
                </div>
            </CheckBox>
            {macro.checked ?
                <MicroMacroGraph items={macroKeys.map(k => ({
                    title: k.charAt(0).toUpperCase() + k.substring(1),
                    get: () => macro.graph[k],
                    set: (value: ElementValue) => macro.setGraph({[k]: value})
                }))}/> : null}
        </div>
    </div>;
}

const GraphsRefRender: React.ForwardRefRenderFunction<CultivarRef, {
    graphs: EntityGraphs;
    setPopup?: (popup: boolean) => void;
    showDrisGraphs: boolean;
}> = (props, ref) => {

    const [macro, setMacro, updateMacro] = useStateSetter(props.graphs.macroGraph);
    const [micro, setMicro, updateMicro] = useStateSetter(props.graphs.microGraph);
    const [molybdenum, setMolybdenum, updatemolybdenum] = useStateSetter(props.graphs.molybdenumGraph);
    const [nickel, setNickel, updateNickel] = useStateSetter(props.graphs.nickelGraph);
    
    const [macroChecked, setMacroChecked] = useState(props.graphs.macroGraphSelected);
    const [microChecked, setMicroChecked] = useState(props.graphs.microGraphSelected);
    const [molybdenumChecked, setMolybdenumChecked] = useState(props.graphs.molybdenumGraphSelected);
    const [nickelChecked, setNickelChecked] = useState(props.graphs.nickelGraphhSelected);
    const [showCloneCrop, setShowCloneCrop] = useState(false)
    const [showCloneCultivar, setShowCloneCultivar] = useState(false)
    

    const [drisGraphs, setDrisGraphs, updateGrisGraphs] = useStateSetterArray<SelectedDrisGraph>([])
    
    useEffect(() => {
        setDrisGraphs(props.graphs.drisGraphs)
    }, [props.graphs])
    

    useImperativeHandle(ref, () => ({
        requestUpdate(): EntityGraphs {
            return {
                cultivarId: props.graphs.cultivarId,
                name: props.graphs.name,
                drisGraphs: drisGraphs,
                macroGraph: macro,
                macroGraphSelected: macroChecked,
                microGraph: micro,
                microGraphSelected: microChecked,
                molybdenumGraph: molybdenum,
                molybdenumGraphSelected: molybdenumChecked,
                nickelGraph: nickel,
                nickelGraphhSelected: nickelChecked
            }
        }
    }))
    
    function selectCrop(selected: GraphCloneDisplay) {
        CropController.get(selected.id).then(resp => {
            setMacroChecked(resp.data.macroGraphSelected);
            setMicroChecked(resp.data.microGraphSelected);
            setMolybdenumChecked(resp.data.molybdenumGraphSelected);
            setNickelChecked(resp.data.nickelGraphhSelected);
            setMicro(resp.data.microGraph);
            setMacro(resp.data.macroGraph);
            setMolybdenum(resp.data.molybdenumGraph);
            setNickel(resp.data.nickelGraph);
            setDrisGraphs(resp.data.drisGraphs);
            // name, and image?
        });
    }
    
    function selectCultivar(selected: GraphCloneDisplay) {
        CropController.getCultivar(selected.id).then(resp => {
            setMacroChecked(resp.data.macroGraphSelected);
            setMicroChecked(resp.data.microGraphSelected);
            setMolybdenumChecked(resp.data.molybdenumGraphSelected);
            setNickelChecked(resp.data.nickelGraphhSelected);
            setMicro(resp.data.microGraph)
            setMacro(resp.data.macroGraph)
            setMolybdenum(resp.data.molybdenumGraph)
            setNickel(resp.data.nickelGraph)
            setDrisGraphs(resp.data.drisGraphs)
        })
    }

    return <div className="w-full p-2">
        <div className="flex">

            <div className="text-2xl font-bold underline">{props.graphs.name}</div>

            <div className="ml-auto">
                <div className="inline-block">Crop</div>
                <svg onClick={() => setShowCloneCrop(true)} xmlns="http://www.w3.org/2000/svg"
                     className="inline-block h-6 w-6 bg-primary-500 rounded text-white m-1 cursor-pointer hover:bg-primary-600"
                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"/>
                </svg>
                <div className="inline-block">Cultivar</div>
                <svg onClick={() => setShowCloneCultivar(true)} xmlns="http://www.w3.org/2000/svg"
                     className="inline-block h-6 w-6 bg-primary-500 rounded text-white m-1 cursor-pointer hover:bg-primary-600"
                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"/>
                </svg>

                {props.setPopup ? <button className="m-1 btn-error btn-sm"
                                          onClick={() => props.setPopup ? props.setPopup(true) : null}>
                    x
                </button> : null}
            </div>
        </div>
        {RenderMicroAndMacro({
                checked: microChecked,
                setChecked: setMicroChecked,
                graph: micro,
                setGraph: updateMicro
            },
            {
                checked: macroChecked,
                setChecked: setMacroChecked,
                graph: macro,
                setGraph: updateMacro
            })}

            {RenderMolybdenumAndNickel({
                
                    checked: molybdenumChecked,
                    setChecked: setMolybdenumChecked,
                    graph: molybdenum,
                    setGraph: updatemolybdenum
                },
                {
                    checked: nickelChecked,
                    setChecked: setNickelChecked,
                    graph: nickel,
                    setGraph: updateNickel
                })}
        
        {props.showDrisGraphs ? 
        <div className='w-full flex flex-wrap'>
            {
                drisGraphs.map((graph, graphIndex) => <div key={graph.id} className='border shadow w-1/2'>
                        <CheckBox renderLabel={true} checked={graph.selected}
                                  onChange={v => updateGrisGraphs(graphIndex, {selected: v})}>
                            <div className="text-2xl text-gray-500 align-middle inline-block">
                                {graph.graphName}
                            </div>
                        </CheckBox>
                        {
                            graph.selected ?
                                <table>
                                    <thead>
                                    {
                                        graph.rows.length > 0 ? <tr>
                                            <td className='pr-2'>Element</td>
                                            <td className='pr-2'>Mean</td>
                                            <td className='pr-2'>CV</td>
                                        </tr> : <tr className='mx-5'><td>No graphs to show</td></tr>
                                    }
                                    </thead>
                                    <tbody>
                                    {graph.rows.map((row, rowIndex) => {
                                        return <tr key={rowIndex}>
                                            <td>{row.expression}</td>
                                            <td>
                                                <Number value={row.mean}
                                                        change={v => updateGrisGraphs(graphIndex, {rows: arrayUpdatePartial(graph.rows, rowIndex, {mean: v})})}/>
                                            </td>
                                            <td>
                                                <Number value={row.cv}
                                                        change={v => updateGrisGraphs(graphIndex, {rows: arrayUpdatePartial(graph.rows, rowIndex, {cv: v})})}/>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                                : null
                        }

                    </div>
                )
            }
        </div> : null}
        
        <LookupName show={showCloneCrop} setShow={setShowCloneCrop} call={CropController.pagedCropGraphs} heading={"Select Graph"} select={selectCrop}/>
        <LookupName show={showCloneCultivar} setShow={setShowCloneCultivar} call={CropController.pagedCultivarGraphs} heading={"Select Graph"} select={selectCultivar}/>
    </div>
}

export default React.forwardRef(GraphsRefRender)
