import React, {MutableRefObject, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import AppContext from "../../appContext";
import PaginationRequestSearch from "../../controllers/PaginationRequestSearch";
import JobDisplay from "../../controllers/JobDisplay";
import {dateFormat} from "../../date";
import ViewJob from "./ViewJob";
import {wrapLoader} from "../../wrapper";
import Jobs, {JobTableFunctions} from "./Jobs";
import {AxiosResponse} from "axios";
import PaginationResponse from "../../controllers/PaginationResponse";
import Pagination from "../../components/Pagination";
import CreateJob, {JobStatus} from "./createjob";


export function useMountedPromise() {
    const mounted = useRef(false);

    function createPromise<T>(promise: Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            promise.then(resp => {
                if (mounted.current)
                    res(resp);
            }).catch(err => {
                rej(err);
            })
        });
    }
    useEffect(() => {
        mounted.current = true;
        
        return () => {
            mounted.current = false;
        }
    }, []);

    return {
        createPromise,
        mounted
    }
}


const JobTable: React.FC<{ 
    completed: boolean,
    archived: boolean,
    componentRef: MutableRefObject<JobTableFunctions | undefined>,
    call: (request: PaginationRequestSearch) => Promise<AxiosResponse<PaginationResponse<JobDisplay>>>
}> = (props) => {
    const context = useContext(AppContext);
    const {createPromise} = useMountedPromise();

    const [data, setData] = useState<PaginationResponse<JobDisplay>>()

    const [paging, setPaging] = useState<PaginationRequestSearch>({
        sortBy: '',
        ascending: true,
        page: 0,
        rowsPerPage: 20,
        search: '',
        all: false
    })

    const [editJobId, setEditJobId] = useState<JobStatus>('closed')

    function editJob(id: JobStatus) {
        setEditJobId(id)
    }

    function refreshJob() {
        loadData(paging)
    }

    function loadData(paging: PaginationRequestSearch) {
        wrapLoader(context, createPromise(props.call(paging)), resp => {
            setData(resp)
        })
    }
    
    useEffect(() => loadData(paging), [])

    const [viewJobShow, setViewJobShow] = useState<boolean>(false)
    const [viewJobId, setViewJobId] = useState<number>()
    
    function viewJob(id: number) {
        setViewJobId(id)
        setViewJobShow(true)
    }
    
    function updateData(newPaging: PaginationRequestSearch) {
        setPaging(newPaging)
        loadData(newPaging)
    }

    useImperativeHandle(props.componentRef, () => ({
        refresh: () => loadData(paging)
    }))
    
    function showFirstEntities(job: JobDisplay, count: number): string {
        const entities = job.entity.filter(s => s !== '' && s !== ' ')
        if (entities.length <= count)
            return entities.join(", ")
        return entities.slice(0, count).join(", ") + "..."
    }

    function getDistinctFarms(job: JobDisplay) {
        return job.farms.join(", ")
    }

    return (
        <div>
            <div>
                <div className="flex flex-wrap pt-2 w-90">
                    {data?.items.map(job => <div
                            title={job.entity.filter(s => s !== '' && s !== ' ').join(", ")}
                            key={job.id}
                            onClick={() => viewJob(job.id)}
                            className="w-1/3 min-w-[280px] inline-block  text-center text-primary-800 p-2 text-2xl">
                            <div className="rounded-lg p-4 shadow-lg bg-gray-100 border">
                                <div className="flex flex-row-reverse">
                                    <svg onClick={(e) => { e.stopPropagation(); editJob(job.id == 0 ? 'create' : job.id) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                                    className="cursor-pointer w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </div>
                                <div className="text-6xl min-w-full">
                                    J{job.id}
                                </div>
                                <div>{showFirstEntities(job, 3)}</div>
                                <div className={"text-xs whitespace-nowrap"}>{getDistinctFarms(job)}</div>
                                <div className={"text-sm whitespace-nowrap"}>{job.samples[0]} to {job.samples[job.samples.length-1]}</div>
                                <div className="text-small">
                                    {job.samplesCompleted}/{job.samples.length}
                                </div>
                                <div className="text-sm whitespace-nowrap">
                                    Created on: { dateFormat(job.created)}
                                </div>
                                <div className="text-sm whitespace-nowrap">
                                    Received on: { dateFormat(job.received)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-end w-full">

                {viewJobId ? <ViewJob id={viewJobId} refresh={() => loadData(paging)} setId={setViewJobId} completed={props.completed}></ViewJob> : null}

            </div>

            <Pagination refresh={() => loadData(paging)} total={data?.total ?? 0} rowsPerPage={paging.rowsPerPage} page={paging.page}
                        gotoPage={(page) => updateData({...paging, page: page})}
                        setRowsPerPage={rows => updateData({...paging, page: 0, rowsPerPage: rows})}/>

            <CreateJob 
                jobId={editJobId} 
                setModelValue={show => {
                    if (show) return;
                    
                    setEditJobId('closed') // clear the job id
                    refreshJob()
            }} />
        </div>
    )
}

export default JobTable;