/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type PaginationResponse from "./PaginationResponse";
import type JobDisplay from "./JobDisplay";
import type PaginationRequestSearch from "./PaginationRequestSearch";
import type JobData from "./JobData";
import type IdRequest from "./IdRequest";
import type FirestoreLab from "./FirestoreLab";
import type TestCarohydrateMatch from "./TestCarohydrateMatch";
import type MatchRequest from "./MatchRequest";
import type JobDataDisplay from "./JobDataDisplay";
import type LabResultRequest from "./LabResultRequest";
import type LabResultEntryRequest from "./LabResultEntryRequest";
import type IdNameDisplay from "./IdNameDisplay";
import type CarbohydrateTissue from "./CarbohydrateTissue";
import type CarbohydrateTissueWithOrgans from "./CarbohydrateTissueWithOrgans";
import type JobRequest from "./JobRequest";

const urls = {
    cropsActive(): string {
        return "/api/lab/cropsactive";
    },
    tissueTypes(): string {
        return "/api/lab/tissuetypes";
    },
    tissueTypesActive(): string {
        return "/api/lab/tissuetypesactive";
    }
}

export default {
    urls,
    jobs(requestSearch: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<JobDisplay>>> {
        return Axios.post("/api/lab/jobs", requestSearch, defaultConfig);
    },
    getData(request: IdRequest): Promise<AxiosResponse<JobData>> {
        return Axios.post("/api/lab/getdata", request, defaultConfig);
    },
    all(): Promise<AxiosResponse<FirestoreLab[]>> {
        return Axios.post("/api/lab/all", defaultConfig);
    },
    jobsCompleted(requestSearch: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<JobDisplay>>> {
        return Axios.post("/api/lab/jobscompleted", requestSearch, defaultConfig);
    },
    jobsArchived(requestSearch: PaginationRequestSearch): Promise<AxiosResponse<PaginationResponse<JobDisplay>>> {
        return Axios.post("/api/lab/jobsarchived", requestSearch, defaultConfig);
    },
    archiveJob(requests: IdRequest[]): Promise<AxiosResponse> {
        return Axios.post("/api/lab/archivejob", requests, defaultConfig);
    },
    matches(requestSearch: MatchRequest): Promise<AxiosResponse<TestCarohydrateMatch[]>> {
        return Axios.post("/api/lab/matches", requestSearch, defaultConfig);
    },
    jobData(request: IdRequest): Promise<AxiosResponse<JobDataDisplay>> {
        return Axios.post("/api/lab/jobdata", request, defaultConfig);
    },
    updateResults(request: LabResultRequest): Promise<AxiosResponse> {
        return Axios.post("/api/lab/updateresults", request, defaultConfig);
    },
    updateResult(request: LabResultEntryRequest): Promise<AxiosResponse> {
        return Axios.post("/api/lab/updateresult", request, defaultConfig);
    },
    removeResult(ids: number[]): Promise<AxiosResponse> {
        return Axios.post("/api/lab/removeresult", ids, defaultConfig);
    },
    cropsActive(): Promise<AxiosResponse<IdNameDisplay[]>> {
        return Axios.get("/api/lab/cropsactive", defaultConfig);
    },
    tissueTypes(): Promise<AxiosResponse<CarbohydrateTissue[]>> {
        return Axios.get("/api/lab/tissuetypes", defaultConfig);
    },
    tissueTypesActive(): Promise<AxiosResponse<CarbohydrateTissueWithOrgans[]>> {
        return Axios.get("/api/lab/tissuetypesactive", defaultConfig);
    },
    upsertJob(request: JobRequest): Promise<AxiosResponse> {
        return Axios.post("/api/lab/upsertjob", request, defaultConfig);
    }
}
