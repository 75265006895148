/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */

enum DrisElement {
    N = "N",
    P = "P",
    K = "K",
    Ca = "Ca",
    Mg = "Mg",
    S = "S",
    Fe = "Fe",
    Mn = "Mn",
    Na = "Na",
    Zn = "Zn",
    Cu = "Cu",
    B = "B",
    Mo = "Mo",
    Ni = "Ni"
}

export default DrisElement;
