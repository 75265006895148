import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../appContext";
import {classNames, wrapLoader} from "../wrapper";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import {dateFormat} from "../date";
import Dialog from "../components/Dialog";
import GetApiDataResponse from "../controllers/GetApiDataResponse";
import CheckBox from "../components/CheckBox";
import CarboResultsDisplayed from "../controllers/CarboResultsDisplayed";
import {distinct} from "../array";
import Success from "../components/Success";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import {AxiosResponse} from "axios";
import PaginationResponse from "../controllers/PaginationResponse";
import FileContentResult from "../controllers/FileContentResult";
import CarbohydrateController from "../controllers/CarbohydrateController";
import DownloadSelectedReport from "../components/DownloadSelectedReport";
import PaginationGroupedLocationRequestSearch from "../controllers/PaginationGroupedLocationRequestSearch";
import CarbohydrateSearchFilter from "../components/CarbohydrateSearchFilter";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/24/solid";

export interface CarbohydrateFilter {
    client: string | null,
    farmName: string | null,
    cropId: number | null,
    fieldBlockNumber: string | null,
    organ: string | null,
    cropGrowthId: number | null
}

const emptyCarbohydrateFilter: CarbohydrateFilter = {
    client: null,
    farmName: null,
    cropId: null,
    fieldBlockNumber: null,
    organ: null,
    cropGrowthId: null
}
const Carbohydrates: React.FC<{
    paged: (request: PaginationGroupedLocationRequestSearch) => Promise<AxiosResponse<PaginationResponse<CarboResultsDisplayed>, any>>
    pagedExcel: (request: PaginationGroupedLocationRequestSearch) => Promise<AxiosResponse<FileContentResult, any>>
}> = (props) => {
    const context = useContext(AppContext);
    const pagedTableRef = useRef<PagedTableFunctions<CarboResultsDisplayed>>()

    const checkGroupWarningState = useWarningState<number | null>(null)
    const [blocksChecked, setBlocksChecked] = useState<number[]>([])

    const [showJson, setShowJson] = useState(false) //: false,
    const [jsonData, setJsonData] = useState<GetApiDataResponse>() //: {},

    const [checkedCarbos, setCheckedCarbos] = useState<{group: number, id: number, sampleNumber: string}[]>([]) //: []

    const [showSettings, setShowSettings] = useState(false)
    const checkedCarbosIds = checkedCarbos.map(c => c.id);

    const carbohydrateFilter = useRef<CarbohydrateFilter | null>(null);
    const [showFilter, setShowFilter] = useState(false);
    
    const [showMerge, setShowMerge] = useState(false)
    

    useEffect(() => {
        // check if you should add or remove blocks
        if (checkedCarbos.length > 0) {
            // all data in current page of table
            const currentData = pagedTableRef.current?.getData() ?? [];
            const allCarboResultsChecked: CarboResultsDisplayed[] = currentData.filter(s => {
                return checkedCarbosIds.includes(s.id)
            })
            const uniqueBlockIds = allCarboResultsChecked.map(s => s.group).reduce((prev: number[], curr) => {
                if (!prev.includes(curr)) prev.push(curr)
                return prev
            }, [])
            if (uniqueBlockIds.length == 0) return;
            uniqueBlockIds.forEach(blockId => {
                const carbsOfBlock = currentData.filter(s => s.group == blockId).map(s => s.id)
                const allCarbsOfBlockChecked = carbsOfBlock.every(s => checkedCarbosIds.includes(s))
                if (allCarbsOfBlockChecked) {
                    if (blocksChecked.includes(blockId)) return
                    setBlocksChecked([...blocksChecked, blockId])
                } else {
                    setBlocksChecked(blocksChecked.filter(s => s !== blockId))
                }
            })
            return
        }
        setBlocksChecked([])
    }, [checkedCarbos])

    function mergeEntries(entries: number[]) {
        wrapLoader(context, CarbohydrateController.mergeEntries({ids: entries}), () => {
            // Show snack
            context.showSnack(<Success title={'Entries merged'}/>)
            // refresh table
            pagedTableRef.current?.refresh()
            // clear selected
            setCheckedCarbos([])
            // close
            setShowMerge(false)
        })
    }

    function selectAll(s: boolean) {
        const currentData = pagedTableRef.current?.getData() ?? [];
        const ids = currentData.map(p => ({id: p.id, group: p.group, sampleNumber: p.sampleNumber}));
        if (s) {
            setCheckedCarbos(distinct(checkedCarbos.concat(ids)));
        } else {
            const filter = checkedCarbos.filter(c => !ids.some(s => s.id == c.id));
            setCheckedCarbos(filter);
        }
    }

    function getJson(id: number | null) {
        if (id === null) return
        wrapLoader(context, CarbohydrateController.getApiData({id}), resp => {
            setShowJson(true)
            setJsonData(resp)
        })
    }

    function checkedArray(checked: boolean, group: number, id: number, sampleNumber: string) {
        if (checked) {
            setCheckedCarbos([...checkedCarbos, {group, id, sampleNumber}])
        } else {
            setCheckedCarbos(checkedCarbos.filter(carb => carb.id !== id))
        }
    }

    function downloadSelectedExcel() {
        if (checkedCarbos.length === 0) return;
        window.location.href = CarbohydrateController.urls.pagedExcelIds(checkedCarbosIds);
        context.showSnack(<Success title={'Starting download'}/>)
    }


    function isAllChecked(data: CarboResultsDisplayed[]): boolean {
        return data.every(row => checkedCarbosIds.includes(row.id))
    }

    function showWarningForGroupCheck(group: number) {
        if (blocksChecked.includes(group)) {
            checkGroupWarningState.show(`Are you sure you want to uncheck this group (id: ${group})?`, group)
            return
        }
        checkGroupWarningState.show(`Are you sure you want to check this group (id: ${group})?`, group)
    }

    function selectGroup() {
        const blockId: number | null = checkGroupWarningState.state.data
        const carbsOfBlock = pagedTableRef.current?.getData().filter(s => s.group == blockId).map(s => ({id: s.id, group: s.group, sampleNumber: s.sampleNumber}))
        if (!carbsOfBlock || !blockId) return
        if (checkedCarbos.some(r => carbsOfBlock.some(s => s.group == r.group))) {
            setCheckedCarbos(checkedCarbos.filter(s => !carbsOfBlock.find(r => r.group == s.group)))
            setBlocksChecked(blocksChecked.filter(s => s !== blockId))
            return
        }
        setCheckedCarbos([...checkedCarbos, ...carbsOfBlock])
        setBlocksChecked([...blocksChecked, blockId])
    }

    function showSettingsPopup() {
        if (checkedCarbos.length == 0) return;
        setShowSettings(true)
    }

    function clearSelected() {
        setCheckedCarbos([])
    }

    function setShowMergePopup() {
        if (checkedCarbos.length == 0) return;
        setShowMerge(true)
    }

    return (
        <div className="bg-white px-2 pb-2 shadow">
            <div className="sticky top-0 z-50 bg-white pt-3 pb-2 px-2">
                <div onClick={downloadSelectedExcel}
                     className={classNames("btn mb-2", checkedCarbos.length === 0 ? "bg-gray-400" : "btn-primary")}>
                    Download Selected
                </div>
                <div onClick={showSettingsPopup}
                     className={classNames("btn mb-2", checkedCarbos.length === 0 ? "bg-gray-400" : "btn-primary")}>
                    Download Selected Report
                </div>
                <div onClick={setShowMergePopup}
                     className={classNames("btn mb-2", checkedCarbos.length === 0 ? "bg-gray-400" : "btn-primary")}>
                    Merge Entries
                </div>
                <div className='text-gray-700 text-sm pl-2 pb-1'>
                    You have selected {checkedCarbos.length} which
                    creates {new Set(checkedCarbos.map(c => c.group)).size} pages.
                    <button onClick={clearSelected}
                            className="text-primary-600 hover:opacity-80 ml-2 border shadow-lg px-1 rounded">clear</button>
                </div>
            </div>

            <PagedSearchTable<CarboResultsDisplayed, PaginationGroupedLocationRequestSearch>
                componentRef={pagedTableRef}
                call={request => props.paged({
                    ...request,
                    client: carbohydrateFilter.current?.client ?? null,
                    farmName: carbohydrateFilter.current?.farmName ?? null,
                    cropId: carbohydrateFilter.current?.cropId ?? null,
                    fieldBlockNumber: carbohydrateFilter.current?.fieldBlockNumber ?? null,
                    cropGrowthId: carbohydrateFilter.current?.cropGrowthId ?? null,
                    organ: carbohydrateFilter.current?.organ ?? null
                                                       })}
                                                       keyExtractor={i => i.id}
                                                       groupBy={i => i?.group ?? 0}
                                                       downloadExcelCall={props.pagedExcel}
                                                       rowDoubleClick={item => showWarningForGroupCheck(item.group)}
                                                       inputSlot={
                                                           <CarbohydrateSearchFilter 
                                                               show={showFilter}
                                                               reset={() => {
                                                                     carbohydrateFilter.current = emptyCarbohydrateFilter;
                                                                     pagedTableRef.current!.refresh();
                                                               }}
                                                               onClose={() => {
                                                                   setShowFilter(false)
                                                               }} 
                                                               onSearch={filter => {
                                                                   carbohydrateFilter.current = filter;
                                                                   // will trigger the call method on this search table component
                                                                   pagedTableRef.current!.refresh();
                                                               }} />}
                                                         searchSlot={
                                                             <div className="btn bg-primary" onClick={() => setShowFilter(!showFilter)}>
                                                                 <AdjustmentsHorizontalIcon className="w-6 h-6" />
                                                             </div>
                                                         }
                                                       columns={[
                {
                    header: () => <CheckBox checked={isAllChecked(pagedTableRef.current?.getData() ?? [])} onChange={select => selectAll(select)}/>,
                    row: (item) => <CheckBox checked={checkedCarbosIds.includes(item.id)}
                                             onChange={v => checkedArray(v, item.group, item.id, item.sampleNumber)}/>,
                },
                {
                    header: 'Sample Number',
                    row: (item, index) => <div>{item.sampleNumber}</div>
                },
                {
                    header: 'Entity',
                    row: (item) => item.entity

                },
                {
                    header: 'Farm',
                    row: (item) => item.farmName
                },
                {
                    header: 'Crop / Cultivar',
                    row: (item) => `${item.crop} / ${item.cultivar}`
                },
                {
                    header: 'Region',
                    row: (item) => item.regionName ?? ""
                },
                {
                    header: 'Field Block Number',
                    row: (item) => item.fieldBlockNumber

                },
                {
                    header: 'Growth Phase (Organ)',
                    row: (item) => <span>{item.cropGrowth} ({item.linkedTissueName})</span> 

                },
                {
                    header: 'Organ',
                    row: (item) => item.tissue

                },
                {
                    header: 'Sugar',
                    row: (item) => item.sugar?.toFixed(2)

                },
                {
                    header: 'Starch',
                    row: (item) => item.starch?.toFixed(2)

                },
                {
                    header: 'Field Date',
                    row: (item) => dateFormat(item.sampleDate), 

                },
                {
                    header: 'Job Created',
                    row: (item) => dateFormat(item.createdOn) // date job was created

                },
                {
                    header: 'Result',
                    row: (item) => dateFormat(item.completedOn)

                },
                {
                    header: 'Actions',
                    row: (item) => <div className="flex">
                        <div className={`m-1 btn-sm ${item.carboHydrateId == null ? "bg-gray-500" :"bg-primary-500"}`}
                             onClick={() => getJson(item.carboHydrateId)}>debug</div>
                    </div>
                },
            ]}
            />
            <Dialog show={showJson} setShow={setShowJson} title="Json" body={
                <>
                    <div className="flex text-left">
                        <div className="w-1/2 px-2">
                            <p className="text-xl font-bold">Original</p>
                            <pre className="whitespace-pre-wrap text-xs">{jsonData?.original}</pre>
                        </div>
                        <div className="w-1/2 px-2">
                            <p className="text-xl font-bold">Mapped</p>
                            <pre className="whitespace-pre-wrap text-xs">{JSON.stringify(jsonData?.mapped)}</pre>
                        </div>
                    </div>
                </>
            }>
            </Dialog>
            <WarningPopup state={checkGroupWarningState} onYes={() => selectGroup()}/>
            
            <Dialog title={"Are you sure you want to merge these entries?"} show={showMerge}
                    setShow={setShowMerge} body={
                <div>
                    <div className="text-sm text-gray-600">
                        {checkedCarbos.map(c => <div key={c.id}>{c.sampleNumber}</div>)}
                    </div>
                    <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                        <div className="btn bg-red-500" onClick={() => setShowMerge(false)}>discard</div>
                        <div className="btn bg-primary-500"
                             onClick={() => mergeEntries(checkedCarbos.map(c => c.id))}>Merge</div>
                    </div>
                </div>
            }/>

            <DownloadSelectedReport
                showSettings={showSettings}
                setShowSettings={setShowSettings}
                checkedCarbos={checkedCarbos}/>
        </div>
    )
}

export default Carbohydrates
