import React, {useContext, useEffect} from "react";
import AppContext from "../../appContext";
import CropController from "../../controllers/CropController";
import {arrayPush, useStateSetterArray} from "../../immutableState";
import {wrapLoader} from "../../wrapper";
import {RenderMicroAndMacro, RenderMolybdenumAndNickel} from "./Graphs";
import Input from "../../components/Input";
import {emptyMacro, emptyMicro, emptyMolybdenum, emptyNickel} from "./graphHelper";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";
import GrowthStageRequest from "../../controllers/GrowthStageRequest";
import {showSuccessOrFailed} from "../../Snacks";



function empty(): GrowthStageRequest {
    return {
        id: 0,
        name: '',
        microGraph: emptyMicro(),
        microGraphId: 0,
        macroGraph: emptyMacro(),
        macroGraphId: 0,
        molybdenumGraph: emptyMolybdenum(),
        molybdenumGraphId: 0,
        nickelGraph: emptyNickel(),
        nickelGraphId: 0,
        microGraphChecked: false,
        macroGraphChecked: false,
        molybdenumGraphChecked: false,
        nickelGraphChecked: false
    }
}

const GrowthStages: React.FC<{
    cropId: number;
    close: (saved: boolean) => void;
}> = (props) => {
    const context = useContext(AppContext);
    const warningState = useWarningState<{ id: number, index: number }>({id: 0, index: 0})
    const [data, setData, updateData] = useStateSetterArray<GrowthStageRequest>([]);

    useEffect(() => {
        wrapLoader(context, CropController.growthStages({id: props.cropId}),resp => {
            setData(resp.map<GrowthStageRequest>(d => ({
                ...d,
                macroGraphChecked: d.macroGraphId > 0,
                microGraphChecked: d.microGraphId > 0,
                molybdenumGraphChecked: d.molybdenumGraphId > 0,
                nickelGraphChecked: d.molybdenumGraphId > 0
            })));
        })
    }, [])

    function newGrowthStage() {
        setData(arrayPush(data, empty()));
    }

    function upsertGrowthstages() {
        showSuccessOrFailed(context, CropController.upsertGrowthStages({
            cropId: props.cropId,
            growthStages: data
        })).then(() => {
            props.close(true)
        })
    }
    
    function deleteGraphs(id: number, index: number) {
        setData(data.filter((d, i ) => id === 0 ? i !== index : d.id !== id))
    }
    
    function showWarning(stage: GrowthStageRequest, index:number) {
        warningState.show(stage.name.length > 0 ? `Are you sure you want to delete ${stage.name}? ` : "Are you sure you want to delete this growth?", {id: stage.id, index: index})
    }

    return (
        <>
            <div className="btn-sm btn-primary m-2" onClick={newGrowthStage}>add</div>
            {data.map((stage, index) =>
                <div className='m-1' key={index}>
                    <div className='flex'>
                        <Input className='' value={stage.name} change={v => updateData(index, {name: v})}></Input>
                        <button className="m-1 btn-error btn-sm" onClick={() => showWarning(stage, index)}>x</button>
                    </div>
                    {RenderMicroAndMacro({
                        checked: stage.microGraphChecked,
                        setChecked: (v) => updateData(index, {microGraphChecked: v}),
                        graph: stage.microGraph,
                        setGraph: (v) => updateData(index, {microGraph: {...stage.microGraph, ...v}})
                    }, {
                        checked: stage.macroGraphChecked,
                        setChecked: (v) => updateData(index, {macroGraphChecked: v}),
                        graph: stage.macroGraph,
                        setGraph: (v) => updateData(index, {macroGraph: {...stage.macroGraph, ...v}})
                    })}
                    
                    {RenderMolybdenumAndNickel({
                        checked: stage.molybdenumGraphChecked,
                        setChecked: (v) => updateData(index, {molybdenumGraphChecked: v}),
                        graph: stage.molybdenumGraph,
                        setGraph: (v) => updateData(index, {molybdenumGraph: {...stage.molybdenumGraph, ...v}})
                    }, {
                        checked: stage.nickelGraphChecked,
                        setChecked: (v) => updateData(index, {nickelGraphChecked: v}),
                        graph: stage.nickelGraph,
                        setGraph: (v) => updateData(index, {nickelGraph: {...stage.nickelGraph, ...v}})
                    })}
                        

                </div>
            )}
            <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                <button className="btn btn-error" onClick={() => props.close(false)}>
                    Cancel
                </button>
                <div className="btn btn-primary" onClick={upsertGrowthstages}>
                    Update
                </div>
            </div>
            <WarningPopup state={warningState} onYes={v => deleteGraphs(v.id, v.index)}/>
        </>
    )
}

export default GrowthStages;