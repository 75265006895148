import React, {useContext, useEffect, useState} from "react";
import {showSuccessOrFailed} from "../Snacks";
import AppContext from "../appContext";
import Input from "../components/Input";
import {produce} from "immer";
import CheckBox from "../components/CheckBox";
import SubmissionController from "../controllers/SubmissionController";
import ManualResult from "../controllers/ManualResult";
import Values from "../controllers/Values";
import FileUploader from "../components/FileUploader";
import {ColumnMatcher, parseCSVFile} from "./CSVParse";
import {asOptionalRealNumber} from "../controllers/helper";
import Papa from "papaparse";
import {saveAs} from "file-saver";
import NumberNullable from "../components/NumberNullable";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import {useValidation} from "../validation";
import ManualFileUploader from "../components/ManualFileUploader";

type ValueKeys = keyof Values;

interface ValueEntry {
    code: string,
    name: string,
    unit: string
}

const labEntryMappings: Record<ValueKeys, ValueEntry> = {
    n: {unit: '%', name: 'N', code: 'N(B)'},
    p: {unit: '%', name: 'P', code: 'P(B)'},
    k: {unit: '%', name: 'K', code: 'K(B)'},
    ca: {unit: '%', name: 'Ca', code: 'Ca(B)'},
    mg: {unit: '%', name: 'Mg', code: 'Mg(B)'},
    na: {unit: 'mg/kg', name: 'Na', code: 'Na(B)'},
    fe: {unit: 'mg/kg', name: 'Fe', code: 'Fe(B)'},
    mn: {unit: 'mg/kg', name: 'Mn', code: 'Mn(B)'},
    zn: {unit: 'mg/kg', name: 'Zn', code: 'Zn(B)'},
    cu: {unit: 'mg/kg', name: 'Cu', code: 'Cu(B)'},
    b: {unit: 'mg/kg', name: 'B', code: 'B(B)'},
    mo: {unit: 'mg/kg', name: 'Mo', code: 'Mo(B)'},
    ni: {unit: 'mg/kg', name: 'Ni', code: 'Ni(B)'},
    s: {unit: '%', name: 'S', code: 'S(B)'}
}

function matchArray(...array: string[]): (key: string) => boolean {
    return key => array.includes(key.toLowerCase().trim());
}

const CSVManualResultData: ColumnMatcher<ManualResult>[] = [
    {
        match: matchArray('sample number', 'sample', 'samplenumber', 'reference'),
        populate: (value, entry) => entry.sampleNumber = value
    },
    {
        match: matchArray('lab number', 'lab', 'labnumber'), 
        populate: (value, entry) => {
            entry.labNumber = value;
        }
    },
    {
        match: matchArray('n'), 
        populate: (value, entry) => {
            entry.values.n = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('p'), 
        populate: (value, entry) => {
            entry.values.p = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('k'), 
        populate: (value, entry) => {
            entry.values.k = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('ca'), 
        populate: (value, entry) => {
            entry.values.ca = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('mg'), 
        populate: (value, entry) => {
            entry.values.mg = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('na'), 
        populate: (value, entry) => {
            entry.values.na = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('fe'), 
        populate: (value, entry) => {
            entry.values.fe = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('mn'), 
        populate: (value, entry) => {
            entry.values.mn = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('zn'), 
        populate: (value, entry) => {
            entry.values.zn = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('cu'), 
        populate: (value, entry) => {
            entry.values.cu = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('b'), 
        populate: (value, entry) => {
            entry.values.b = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('mo'), 
        populate: (value, entry) => {
            entry.values.mo = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('ni'), 
        populate: (value, entry) => {
            entry.values.ni = asOptionalRealNumber(value)
        }
    },
    {
        match: matchArray('s'), 
        populate: (value, entry) => {
            entry.values.s = asOptionalRealNumber(value)
        }
    },
]

const keys: (ValueKeys)[] = Object.keys(labEntryMappings) as unknown as (ValueKeys)[];

function createManualResult(sampleNumber: string): ManualResult {
    return {
        sampleNumber,
        na: false,
        labNumber: '',
        values: {
            p: null,
            b: null,
            ca: null,
            cu: null,
            fe: null,
            k: null,
            mg: null,
            mn: null,
            mo: null,
            n: null,
            na: null,
            zn: null,
            ni: null,
            s: null
        }
    }
}

function interfaceSome<T extends object>(type: T, predicate: (value: T[keyof T]) => boolean): boolean {
    return Object.values(type).some(predicate);
}

const ManualLabResult: React.FC<{
    submissionId: number;
    onClose: () => void;
}> = (props) => {
    const context = useContext(AppContext);
    const warningState = useWarningState(null);
    const [rows, setRows] = useState<ManualResult[]>([]);
    const [showUpload, setShowUpload] = useState(false);
    const [rowsNotUploaded, setRowsNotUploaded] = useState(0);

    useEffect(() => {
        // get initial sample number to populate table
        SubmissionController.getSampleNumbersForSubmission(props.submissionId)
            .then(resp => {
                setRows(resp.data.map(sampleNumber => createManualResult(sampleNumber)))
            })
            .catch(err => context.showSnack(<>Could not get samples.</>))
    }, [])
    
    const validation = useValidation({
        everyRow: () => rows.every(row => {
            return row.na 
                ? true 
                : interfaceSome(row.values, f => f != null) 
                    ? true 
                    : row.labNumber.length > 0
        })
    })

    function save() {
        if (!validation.validate()) return
        
        const call = SubmissionController.updateManualSubmission({
            submissionId: props.submissionId,
            manualResult: rows
        })
        showSuccessOrFailed(context, call)
            .then(() => {
                setRowsNotUploaded(0)
                props.onClose()
            })
            .catch(e =>
                context.showSnack(<>Could not save result.</>)
            )
    }
    
    function downloadCSV() {
        const flat = rows.map(row => (
            {
                "Sample Number": row.sampleNumber,
                "Lab Number": row.labNumber,
                N: row.values.n,
                P: row.values.p,
                K: row.values.k,
                Ca: row.values.ca,
                Mg: row.values.mg,
                Na: row.values.na,
                Fe: row.values.fe,
                Mn: row.values.mn,
                Zn: row.values.zn,
                Cu: row.values.cu,
                B: row.values.b,
                Mo: row.values.mo,
                Ni: row.values.ni,
                S: row.values.s
            }
        ))
        const csvData = Papa.unparse(flat);
        const blob = new Blob([csvData], {type: 'text/csv'});
        
        
        saveAs(blob, 'data.csv');
    }

    function emptyManualResult(index: number): ManualResult {
        return {
            sampleNumber: rows[index]?.sampleNumber ?? '',
            na: false,
            labNumber: '',
            values: {
                p: null,
                b: null,
                ca: null,
                cu: null,
                fe: null,
                k: null,
                mg: null,
                mn: null,
                mo: null,
                n: null,
                na: null,
                zn: null,
                ni: null,
                s: null
            }
        }
    }
    
    function showWarningIfValidated() {
        if (!validation.validate()) return 
        
        warningState.show("Are you sure you want to submit this manual submission?", null)
    }

    function csvUpload(file: File) {
        const reader = new FileReader()
        reader.onload = e => {
            const text: string = e.target?.result as string
            magicPaste(text);
        }
        reader.readAsText(file)
    }
    
    function magicPaste(text: string) {
        let rowsNotFound = 0;
        const entries = parseCSVFile<ManualResult>(text, index => emptyManualResult(index), CSVManualResultData)
        setRows(r => r.map((row, index) => {
            const ent = entries.find(e => e.sampleNumber.toLowerCase().trim() == row.sampleNumber.toLowerCase().trim())

            if (ent) {
                return {...ent, sampleNumber: row.sampleNumber}
            }
            rowsNotFound++;
            return row
        }))
        setRowsNotUploaded(rowsNotFound)
    }

    // from excel rows by \n, columns by \t
    function formatClipboard (clipboard: string): string[][] {
        return clipboard.split('\n').map(r => r.split('\t'))
    }

    function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
        const text = event.clipboardData.getData('text');
        if (!text || text === '') { return; }

        const data = formatClipboard(text);

        setRows(currentRows => currentRows.map((row, index) => {
            const rowData = data[index];
            if (rowData) {
                return {
                    ...row,
                    labNumber: rowData[0] ? rowData[0] : row.labNumber,
                    values: {
                        n: asOptionalRealNumber(rowData[1] ?? ""),
                        p: asOptionalRealNumber(rowData[2] ?? ""),
                        k: asOptionalRealNumber(rowData[3] ?? ""),
                        ca: asOptionalRealNumber(rowData[4] ?? ""),
                        mg: asOptionalRealNumber(rowData[5] ?? ""),
                        na: asOptionalRealNumber(rowData[6] ?? ""),
                        fe: asOptionalRealNumber(rowData[7] ?? ""),
                        mn: asOptionalRealNumber(rowData[8] ?? ""),
                        zn: asOptionalRealNumber(rowData[9] ?? ""),
                        cu: asOptionalRealNumber(rowData[10] ?? ""),
                        b: asOptionalRealNumber(rowData[11] ?? ""),
                        mo: asOptionalRealNumber(rowData[12] ?? ""),
                        ni: asOptionalRealNumber(rowData[13] ?? ""),
                        s: asOptionalRealNumber(rowData[14] ?? ""),
                    } as Values
                } as ManualResult;
            }
            return row;
        }));
        event.preventDefault();
    }

    function uploadFiles() {
        setShowUpload(true)
    }

    return <div>
        <table className="m-2">
            <thead>
            <tr>
                <td className="pr-2">Sample Number</td>
                <td className="pr-2">Lab Number</td>
                {keys.map(key => 
                    <td key={labEntryMappings[key].name} 
                        className="pr-2">
                        {labEntryMappings[key].name} {labEntryMappings[key].unit}
                    </td>)}
                <td className="pr-2">N/A</td>
            </tr>
            </thead>

            <tbody>

            {rows.map((result, index) =>
                <tr key={result.sampleNumber + 'input'} className="text-gray-500">
                    <td className="pr-2 w-[10%] ">{result.sampleNumber}</td>
                    <td className="pr-2 w-[10%] ">
                        <Input onPaste={handlePaste}
                               disabled={rows[index]!.na}
                               className="w-full" value={result.labNumber} 
                               change={e => {
                                    setRows(produce(rows, draft => {
                                        draft[index]!.labNumber = e;
                                    }));
                        }}/>
                    </td>

                    {keys.map(key => <td key={result.sampleNumber + 'number' + key} className="pr-2">
                        <NumberNullable value={result.values[key]} 
                                        disabled={rows[index]!.na} 
                                        onPaste={handlePaste}
                                        change={val => {
                            setRows(produce(rows, draft => {
                                draft[index]!.values[key] = val;
                            }));

                        }}/>
                    </td>)}
                    <td>
                        <CheckBox checked={rows[index]!.na} onChange={val => setRows(produce(rows, draft => {
                            draft[index]!.na = val
                        }))}/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>

        <div className="flex-1 flex justify-between items-center ">
            <div className='flex items-end'>
                <div className='btn bg-primary-500 mx-2' onClick={() => downloadCSV()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                    </svg>
                </div>

                <div className='btn bg-primary-500 mx-2'>
                    <FileUploader onChange={file => csvUpload(file)} fileTypes=".csv">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"/>
                        </svg>
                    </FileUploader>
                </div>
                
                <div className='btn bg-primary-500 mx-2' onClick={e => {
                    // get clipboard data
                    navigator.clipboard.readText().then(text => {
                        magicPaste(text);
                    })
                }}>
                    <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
                            <path d="M5 5h2v1c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V5h2v5h2V5c0-1.1-.9-2-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v-2H5V5zm7-2c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1z" fill="currentColor"></path>
                            <path d="M22.3 20.9l-2-2c.58-1.01.95-2.23.51-3.65c-.53-1.72-2.04-3.05-3.84-3.22a4.498 4.498 0 0 0-4.95 4.95c.18 1.79 1.5 3.31 3.22 3.84c1.43.44 2.64.07 3.65-.51l2 2c.39.39 1.01.39 1.4 0s.4-1.02.01-1.41zM16.5 19c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <div className='btn bg-primary-500 mx-2' onClick={() => uploadFiles()}>
                    Upload files
                </div>


                {
                    rowsNotUploaded > 0
                        ? <div className='text-sm text-red-500'>
                            ({rowsNotUploaded} rows not uploaded)
                        </div>
                        : null
                }
            </div>

            <div className='flex justify-between sm:justify-end items-center'>
                {
                    !validation.rules.everyRow 
                        ? <div className="text-xs text-red-500 pr-2">(Please ensure that each row contains at least one entry or is marked as N/A)</div>
                        : null
                }
                <div className="btn btn-primary my-2" onClick={() => showWarningIfValidated()}>Save</div>
            </div>
        </div>
        
        <WarningPopup state={warningState} onYes={save}/>

        <ManualFileUploader show={showUpload}
                            setShow={setShowUpload}
                            submissionId={props.submissionId}/>
    </div>
}

export default ManualLabResult;

