import React, {useContext, useRef, useState} from "react";
import AppContext from "../appContext";
import {useValidation} from "../validation";
import AddressController from "../controllers/AddressController";
import AddressRequest from "../controllers/AddressRequest";
import {showSuccessOrFailed} from "../Snacks";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import Language from "../controllers/Language";
import Address from "../controllers/Address";
import Dialog from "../components/Dialog";
import {EditRow, EditTable} from "../components/Fields";
import Input from "../components/Input";
import {buildSetter} from "../immutableState";
import CheckBox from "../components/CheckBox";

function empty(): AddressRequest {
    return {
        id: 0,
        name: '',
        addressLine: '',
        area: '',
        code: '',
        number: '',
        email: '',
        website: '',
        filePrefix: '',
        isDefault: false
    }
}

const Addresses: React.FC = () => {
    const context = useContext(AppContext);
    const [selected, setSelected] = useState<AddressRequest>(empty())
    const [editModel, setEditModel] = useState<boolean>(false)
    const pagedTableRef = useRef<PagedTableFunctions<Address>>()
    const [setData] = buildSetter(selected, setSelected)

    const validation = useValidation({
        name: () => selected.name.length > 0,
        addressLine: () => selected.addressLine.length > 0,
        area: () => selected.area.length > 0,
        code: () => selected.code.length > 0,
        number: () => selected.number.length > 0,
        email: () => selected.email.length > 0,
        website: () => selected.website.length > 0,
        filePrefix: () => selected.filePrefix.length > 0
    });
    
    function newAddress() {
        setSelected(empty())
        setEditModel(true);
    }

    function setRow(selected: AddressRequest) {
        setSelected(selected)
        setEditModel(true)
    }

    function upsertAddress() {
        if (!validation.validate()) {
            return;
        }
        showSuccessOrFailed(context, AddressController.upsert(selected), 'Address updated').then(resp => {
            setEditModel(false)
            pagedTableRef.current?.refresh();
        })
    }
    
    function setDefaultAddress(id: number) {
        showSuccessOrFailed(context, AddressController.setDefaultAddress({id}), 'Address updated').then(resp => {
            pagedTableRef.current?.refresh();
        })
    }

    return (
        <div>
            <div className="pb-2">
                <div className="btn bg-primary" onClick={newAddress}>Add Address</div>
            </div>
            <PagedSearchTable componentRef={pagedTableRef} 
                              call={AddressController.paged} 
                              keyExtractor={c => c.id} 
                              columns={[
                                  {
                                      header: 'Name',
                                      key: 'name',
                                      row: (row) => row.name
                                  },
                                  {
                                      header: 'Address Line',
                                      key: 'addressLine',
                                      row: (row) => row.addressLine
                                  },
                                  {
                                      header: 'Area',
                                      key: 'area',
                                      row: (row) => row.area
                                  },
                                  {
                                      header: 'Code',
                                      key: 'code',
                                      row: (row) => row.code
                                  },
                                  {
                                      header: 'Number',
                                      key: 'number',
                                      row: (row) => row.number
                                  },
                                  {
                                      header: 'Email',
                                      key: 'email',
                                      row: (row) => row.email
                                  },
                                  {
                                      header: 'Website',
                                      key: 'website',
                                      row: (row) => row.website
                                  },
                                  {
                                      header: 'File prefix',
                                      key: 'File prefix',
                                      row: (row) => row.filePrefix
                                  },
                                  {
                                      header: 'Default',
                                      key: 'defaultAddress',
                                      row: (row) => <div className="text-center">
                                          <CheckBox checked={row.isDefault ?? false} onChange={() => setDefaultAddress(row.id)}/>
                                      </div>
                                  },
                                  {
                                      header: <div className='text-right'>Actions</div>,
                                      key: 'actions',
                                      row: (row) => <div className='text-right'><div className="btn-primary btn-sm" onClick={() => setRow({...row as AddressRequest})}>edit</div></div>
                                  },
                              ]}/>
            <Dialog title={'Address'}
                    show={editModel}
                    setShow={setEditModel}
                    body={[
                        <EditTable discard={() => setEditModel(false)}
                                   save={() => upsertAddress()}
                                   key={selected.id}
                                   saveWord={selected.id ? 'update' : 'add'}>
                            {EditRow('Name', <Input value={selected.name}
                                                    change={v => setData({name: v})}/>, validation.rules.name, 'Name required')}
                            {EditRow('Address Line', <Input value={selected.addressLine}
                                                    change={v => setData({addressLine: v})}/>, validation.rules.addressLine, 'Address required')}
                            {EditRow('Area', <Input value={selected.area}
                                                    change={v => setData({area: v})}/>, validation.rules.area, 'Area required')}
                            {EditRow('Code', <Input value={selected.code}
                                                    change={v => setData({code: v})}/>, validation.rules.code, 'Code required')}
                            {EditRow('Number', <Input value={selected.number}
                                                    change={v => setData({number: v})}/>, validation.rules.number, 'Number required')}
                            {EditRow('Email', <Input value={selected.email}
                                                    change={v => setData({email: v})}/>, validation.rules.email, 'Email required')}
                            {EditRow('Website', <Input value={selected.website}
                                                    change={v => setData({website: v})}/>, validation.rules.website, 'Website required')}
                            {EditRow('File prefix', <Input value={selected.filePrefix}
                                                    change={v => setData({filePrefix: v})}/>, validation.rules.filePrefix, 'File prefix required')}
                            

                        </EditTable>
                    ]} />
        </div>
    )
}

export default Addresses;