import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../appContext";
import CropController from "../../controllers/CropController";
import {wrapLoader} from "../../wrapper";
import EntityGraphs from "../../controllers/EntityGraphs";
import Graphs from "./Graphs";
import {showSuccessOrFailed} from "../../Snacks";
import Dialog from "../../components/Dialog";
import SelectNumber from "../../components/SelectNumber";
import {EditRow, EditTable} from "../../components/Fields";
import Input from "../../components/Input";
import {arrayInsert} from "../../immutableState";
import {emptyMacro, emptyMicro, emptyMolybdenum, emptyNickel} from "./graphHelper";

export interface CultivarRef {
    requestUpdate: () => EntityGraphs;
}

const Cultivars: React.FC<{
    cropId: number;
    close: (saved: boolean) => void;
}> = (props) => {
    const context = useContext(AppContext);
    const [data, setData] = useState<EntityGraphs[] | null>(null);
    const [showWarning, setShowWarning] = useState<{ show: boolean, cultivarId: number }>({show: false, cultivarId: 0})
    const [moveCultivar, setMoveCultivar] = useState<number>(0)
    const cultivarRef = useRef<(CultivarRef | null)[]>([]);
    const [add, setAdd]= useState(false)
    const [name, setName] = useState("")
    
    useEffect(() => {
        wrapLoader(context, CropController.cultivars({id: props.cropId}), resp => {
            setData(resp);
        })
    }, [])

    function upsertCultivars() {
        const updatedList = cultivarRef.current
            .map(ref => ref!.requestUpdate())
            .filter(c => ({...c, drisGraphs: c.drisGraphs.filter(d => d.selected)}));

        showSuccessOrFailed(context, CropController.upsertCultivar({
            cropId: props.cropId,
            entities: updatedList
        })).then(() => {
            props.close(true);
        })
    }

    function confirmDelete(){
        showSuccessOrFailed(context, CropController.deleteCultivar({cultivarId: showWarning.cultivarId, linkCultivarId: moveCultivar})).then(() => {
            wrapLoader(context, CropController.cultivars({id: props.cropId}), resp => {
                cancelDeleteCultivar()
                setData(resp);
            })
        })
    }

    function cancelDeleteCultivar() {
        setMoveCultivar(0)
        setShowWarning({show: false, cultivarId: 0})
    }

    function newCultivar() {
        setData(arrayInsert(data!, {
            molybdenumGraph: emptyMolybdenum(),
            molybdenumGraphSelected: false,
            nickelGraph: emptyNickel(),
            nickelGraphhSelected: false,
            cultivarId: 0,
            drisGraphs: [],
            macroGraph: emptyMacro(),
            macroGraphSelected: false,
            microGraph: emptyMicro(),
            microGraphSelected: false,
            name: name
        }))
        setAdd(false)
        setName("")
    }

    return <>

        {/*Disable add cultivar for now*/}
        {/*<div className="btn btn-primary m-2 mb-2" onClick={() => setAdd(true)}>+</div>*/}
        
        {data === null  || data.length === 0
            ? <div className='m-3 mx-5 text-center'>No cultivar data available</div>
            : data.map((cultivar, index) => <Graphs showDrisGraphs={false} setPopup={() => setShowWarning({show: true, cultivarId: cultivar.cultivarId})} key={index} ref={r => cultivarRef.current[index] = r} graphs={cultivar}/>)
        }

        <div className="text-right bg-white p-2 border-t sticky bottom-0">
            <button className="btn btn-error" onClick={() => props.close(false)}>
                Cancel
            </button>
            {data === null  || data.length === 0
                ? null
                : <div className="btn btn-primary" onClick={() => upsertCultivars()}>
                    Update
                  </div>
            }
        </div>

        <Dialog title={"Add new Cultivar"} 
                show={add} 
                setShow={setAdd}
                body={<>
                    <EditTable discard={() => setAdd(false)} save={() => newCultivar()}
                               saveWord={ 'add' } >
                        
                            {EditRow  ('Name', <Input focus={true} value={name}
                                change={v => {
                                setName(v)
                                    
                            }}/>)}
                        

                    </EditTable>
                </>}
        
        
        /> 
        
        
        <Dialog title={'Delete Cultivar'} show={showWarning.show} setShow={(s) => setShowWarning({...showWarning, show: false})}
                body={<div className="p-2">
                    <div>
                        Link to:
                    </div>
                    {
                        data !== null
                            ? <SelectNumber className='my-2' options={data.filter((d) => d.cultivarId !== showWarning.cultivarId )} textFunc={t => t.name} valueFunc={v => v.cultivarId} value={moveCultivar} onChange={v => setMoveCultivar(v)}/>
                            : <div className='my-2'>No options available</div>

                    }
                    <div className="text-right p-2 border-t sticky bottom-0 bg-white">
                        <button className="btn btn-error" onClick={() => {
                            cancelDeleteCultivar()}}>cancel</button>
                        <div className="btn btn-primary" onClick={confirmDelete}>
                            link & delete
                        </div>
                    </div>
                </div>}/>
        
    </>
}

export default Cultivars;