/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.2
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {type AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig} from "./helper";
import type FirestoreAgronomist from "./FirestoreAgronomist";

const urls = {
    index(): string {
        return "/api/agronomist/index";
    }
}

export default {
    urls,
    index(): Promise<AxiosResponse<FirestoreAgronomist[]>> {
        return Axios.get("/api/agronomist/index", defaultConfig);
    }
}
