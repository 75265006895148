import {EditTable, Fields} from "./Fields";
import SelectNumberNullable from "./SelectNumberNullable";
import Input from "./Input";
import SelectNumber from "./SelectNumber";
import Dialog from "./Dialog";
import React, {useContext, useEffect, useState} from "react";
import Language from "../controllers/Language";
import {useValidation} from "../validation";
import CarbohydrateController from "../controllers/CarbohydrateController";
import Success from "./Success";
import AppContext from "../appContext";
import LanguageController from "../controllers/LanguageController";
import CheckBox from "./CheckBox";
import AgronomistController from "../controllers/AgronomistController";
import FirestoreAgronomist from "../controllers/FirestoreAgronomist";
import DistributorController from "../controllers/DistributorController";
import Distributor from "../controllers/Distributor";



const DownloadSelectedReport: React.FC<{
    showSettings: boolean;
    setShowSettings: (v: boolean) => void;
    checkedCarbos: { group: number, id: number }[];
}> = (props) => {
    const context = useContext(AppContext)
    const [languages, setLanguages] = useState<Language[]>([])
    const [selectedLanguage, setSelectedLanguage] = useState<number | null>(null)
    const [includeSummary, setIncludeSummary] = useState(false)
    
    const [clientName, setClientName] = useState("")
    const [farmName, setFarmName] = useState("")
    const [agronomist, setAgronomist] = useState<FirestoreAgronomist | null>(null)
    const [agent, setAgent] = useState("")
    const [distributorId, setDistributorId] = useState<number | null>(null)
    
    const [agronomistsList, setAgronomistsList] = useState<FirestoreAgronomist[]>([])
    const [distributors, setDistributors] = useState<Distributor[]>([])
    
    const validation = useValidation({
        language: () => selectedLanguage != null,
        client: () => clientName.length > 0,
        farm: () => farmName.length > 0,
        agent: () => agent.length > 0,
        agronomist: () => agronomist != null,
        distributor: () => distributorId != null
    })
    
    useEffect(() => {
        const hideLoader = context.showLoader()
        Promise.all([
            AgronomistController.index().then(resp => {
                setAgronomistsList(resp.data)
            }),
            DistributorController.all().then(resp => {
                setDistributors(resp.data)
            }),
            LanguageController.index().then(resp => setLanguages(resp.data))
        ]).finally(hideLoader)
    }, [])

    useEffect(() => {
        const firstCarbo = props.checkedCarbos[0]

        if (firstCarbo != undefined) {
            const hideLoader = context.showLoader()
            
        CarbohydrateController.getLabResult({id: firstCarbo.id}).then(resp => {
                const foundAgronomist = agronomistsList.find(a => a.name?.toLowerCase() == resp.data.agronomist?.toLowerCase())
                if (foundAgronomist != undefined)
                    setAgronomist(foundAgronomist)
                setClientName(resp.data.entity ?? "")
                setFarmName(resp.data.farmName ?? "")
                setAgent(resp.data.agent ?? "")
            }).finally(hideLoader)
        }
    }, [props.showSettings]);

    function closeDownloadPopup() {
        props.setShowSettings(false)
        setSelectedLanguage(null)
    }

    function downloadSelectedBlocksReport() {
        if (!validation.validate() || selectedLanguage == null) return;
        
        // custom basic serialization containing the group and the id
        const resultIds = props.checkedCarbos.map(r => `${r.group},${r.id}`).join(';');
        const blockReportUrl = CarbohydrateController.urls.blockReport({
            result: resultIds,
            langId: selectedLanguage,
            includeSummary,
            client: clientName,
            farm: farmName,
            agriculturalist: agronomist?.name ?? "",
            distributor: distributorId ?? 0,
            agent
        });
        context.showSnack(<Success title={"Starting download"}/>)
        if (context.initial.isDev) {
            window.open(blockReportUrl)
            return;
        }
        window.location.href = blockReportUrl;
    }
    
    return <Dialog title="Download selected report"
                   show={props.showSettings}
                   setShow={closeDownloadPopup}>
        <EditTable discard={closeDownloadPopup}
                   save={() => downloadSelectedBlocksReport()}
                   saveWord="Download">
            <div className="border m-2 p-2 border-primary shadow-md w-[600px]">
                <Fields columns={1} fields={[
                    {
                        label: 'Language',
                        value: <SelectNumberNullable
                            className={!validation.rules.language ? "border border-error-600" : ""}
                            options={languages}
                            textFunc={t => t.name ?? ""}
                            valueFunc={v => v.id}
                            value={selectedLanguage}
                            onChange={v => setSelectedLanguage(v)}/>,
                    },
                    {
                        label: 'Client Name',
                        value: <Input className={!validation.rules.client ? "border border-error-600" : ""} 
                                      value={clientName} 
                                      change={v => setClientName(v)}/>
                    },
                    {
                        label: 'Farm Name',
                        value: <Input className={!validation.rules.farm ? "border border-error-600" : ""}
                                      value={farmName} 
                                      change={v => setFarmName(v)}/>
                    },
                    {
                        label: 'Agriculturist',
                        value: <SelectNumber options={agronomistsList}
                                             className={!validation.rules.agronomist ? "border border-error-600" : ""}
                                             textFunc={t => t.name ?? ""}
                                             valueFunc={v => v.id}
                                             value={agronomist?.id ?? 0}
                                             onChange={v => setAgronomist(agronomistsList.find(f => f.id == v) ?? null)}/>
                    },
                    {
                        label: 'Agent',
                        value: <Input className={!validation.rules.agent ? "border border-error-600" : ""}
                                      value={agent} 
                                      change={v => setAgent(v)}/>
                    },
                    {
                        label: 'Distributors',
                        value: <SelectNumberNullable options={distributors}
                                                     className={!validation.rules.distributor ? "border border-error-600" : ""}
                                                     textFunc={r => r.name ?? ''} 
                                                     valueFunc={r => r.id} value={distributorId} 
                                                     onChange={v => setDistributorId(distributors.find(d => d.id == v)?.id ?? null)}/>
                    },
                    {
                        label: 'Include Summary',
                        value: <CheckBox checked={includeSummary} onChange={v => {
                            setIncludeSummary(v)
                        }}/>
                    },
                ]}/>
            </div>

        </EditTable>
    </Dialog>
}

export default DownloadSelectedReport;